<template>
  <div>
    <PageBar Image="img/essay_p_bn.jpg" :Breadcrumbs="Breadcrumbs"></PageBar>

    <!----------------- 學報 、季刊及相關評論 管理系統 列表頁 ---------------->
    <div class="main advSearch">
      <div class="mainSearch">
        <div class="mainSearchTab">
          │
          <a href="javascript:void(0)" id="mainSearch2" class="act">全文檢索</a>
          │
        </div>
        <div class="searchRow">
          <input type="text" v-model="Keyword" />
          <a href="javascript:void(0);" class="mainSearchBtn" @click="search">
            <img src="img/search.svg" alt="" />
          </a>
        </div>
      </div>
      <div class="typeTab">
        <ul>
          <li>
            <a
              href="javascript:void(0);"
              :class="{ act: Type == 0 }"
              @click="switchType(0)"
              >學報
              <span>{{ Summery[0] | commaFormat }}</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              :class="{ act: Type == 1 }"
              @click="switchType(1)"
              >季刊
              <span>{{ Summery[1] | commaFormat }}</span>
            </a>
          </li>
          <li>
            <a
              href="javascript:void(0);"
              :class="{ act: Type == 2 }"
              @click="switchType(2)"
              >評論 <span>{{ Summery[2] | commaFormat }}</span></a
            >
          </li>
        </ul>
      </div>
      <div class="filterCol">
        <h5 class="topTip">依下方條件來精確結果</h5>

        <div
          class="filterType"
          v-for="c in Categories"
          :key="c.JournalCategoryID"
        >
          <h3>{{ c.Name }}</h3>
          <ul>
            <template v-for="c1 in c.Children">
              <li
                :key="c1.JournalCategoryID"
              >
                <input
                  type="checkbox"
                  :value="c1.JournalCategoryID"
                  v-model="SelectedCategories"
                />
                <label
                  >{{ c1.Name }}({{
                    CategorySummery[c1.JournalCategoryID] || 0
                  }})</label
                >
              </li>
            </template>
          </ul>
        </div>
        <div class="filterType" v-if="Years.length > 0">
          <h3>年代</h3>
          <ul>
            <li v-for="y in Years" :key="y.Year">
              <input
                type="checkbox"
                :key="y.Year"
                :value="y.Year"
                v-model="SelectedYears"
              />
              <label>{{ y.Year }}({{ y.Count }})</label>
            </li>
          </ul>
        </div>
      </div>
      <div class="coursesContainer advSearch">
        <div class="advancedSearch">
          <input
            id="resultResearch_docNameALKeywordsSummary"
            placeholder="篇名.關鍵字.摘要"
            type="text"
            v-model="Keyword2"
          />
          <input
            id="resultResearch_author"
            placeholder="作者"
            type="text"
            v-model="Author"
          />
          <input
            id="resultResearch_title"
            placeholder="刊名"
            type="text"
            v-model="FileTitle"
          />
          <input
            id="resultResearch_startYear"
            placeholder="起始年"
            type="number"
            v-model="StartDay"
          />─
          <input
            id="resultResearch_endYear"
            placeholder="結束年"
            type="number"
            v-model="EndDay"
          />
          <a
            class="advancedSearchButton"
            href="javascript:void(0);"
            @click="search2"
            >檢索結果再查詢</a
          >
        </div>
        <div class="sorts">
          <div class="sortBy perpage">
            <span>每頁</span>
            <select v-model="PageSize">
              <option :value="10">10筆</option>
              <option :value="20">20筆</option>
              <option :value="30">30筆</option>
              <option :value="40">40筆</option>
              <option :value="50">50筆</option>
            </select>
            共 {{ Count | commaFormat }} 筆 ，
            {{ (StartIndex + 1) | commaFormat }} -
            {{ EndIndex | commaFormat }} 筆
          </div>

          <div class="sortBy">
            <span>排序依</span>
            <select v-model="SortBy">
              <option :value="0">發布日期</option>
              <option :value="1">熱門程度由多至少</option>
              <option :value="2">熱門程度由少至多</option>
            </select>
          </div>
        </div>

        <div class="pagination topPagination">
          <div class="prev10" v-if="ShowPrev10">
            <img src="img/prev10.png" alt="" @click="prev10" />
          </div>
          <ul>
            <li v-for="p in Pages" :key="p">
              <a
                :class="{ act: p == Page }"
                href="javascript:void(0);"
                @click="showPage(p)"
                >{{ p }}</a
              >
            </li>
          </ul>
          <div class="next10" v-if="ShowNext10">
            <img src="img/next10.png" alt="" @click="next10" />
          </div>
        </div>

        <div class="courseList c_list">
          <div class="list_style essay">
            <table>
              <tr v-for="(j, i) in List" :key="j.JournalID">
                <td>{{ StartIndex + i + 1 }}</td>
                <td>
                  <h4>
                    <a href="javascript:void(0);" @click="goto(j)">{{
                      j.Title
                    }}</a>
                  </h4>
                  <div class="desc">
                    <p v-if="j.Authors">
                      <a
                        href="javascript:void(0);"
                        v-for="(a, i) in j.Authors"
                        :key="a.AuthorID"
                        >{{ a.Name }}
                        <template v-if="i < j.Authors.length - 1"
                          >;</template
                        ></a
                      >
                    </p>

                    <p>
                      <a href="javascript:void(0);"
                        >{{ j.JournalFileTitle }} {{ j.JournalInfo }},
                        {{ j.JournalPage }}</a
                      >
                    </p>
                    <p v-if="j.Keyword">
                      <a
                        class="keyword"
                        href="javascript:void(0);"
                        v-for="(k, i) in j.Keywords"
                        :key="k"
                        >{{ k }}
                        <template v-if="i < j.Keywords.length - 1">;</template>
                      </a>
                    </p>
                    <p class="tdBottom" v-if="j.JournalFileUrl">
                      <a
                        href="javascript:void(0);"
                        @click="download(j)"
                        class="download-icon"
                      >
                        <img src="img/download.svg" alt="全文下載" />
                        全文下載
                      </a>
                    </p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="pagination">
          <div class="prev10" v-if="ShowPrev10">
            <img src="img/prev10.png" alt="" @click="prev10" />
          </div>
          <ul>
            <li v-for="p in Pages" :key="p">
              <a
                :class="{ act: p == Page }"
                href="javascript:void(0);"
                @click="showPage(p)"
                >{{ p }}</a
              >
            </li>
          </ul>
          <div class="next10" v-if="ShowNext10">
            <img src="img/next10.png" alt="" @click="next10" />
          </div>
        </div>
      </div>
      <div class="popUp downloadUsage hide">
        <div class="alertWindow">
          <div class="p_head">
            <p>下載用途</p>
          </div>
          <div class="p_content">
            <div class="formRow">
              <label for="learning">
                <input
                  type="checkbox"
                  id="learning"
                  v-model="Usage.Uses"
                  value="學術研究"
                />
                學術研究
              </label>
            </div>
            <div class="formRow">
              <label for="personal">
                <input
                  type="checkbox"
                  id="personal"
                  v-model="Usage.Uses"
                  value="個人工作所需"
                />
                個人工作所需
              </label>
            </div>
            <div class="formRow">
              <label for="">
                <input type="checkbox" v-model="Usage.ShowElse" />
                其他(請說明)
              </label>
              <input
                type="text"
                placeholder="other"
                v-if="Usage.ShowElse"
                v-model="Usage.Else"
              />
            </div>
          </div>
          <div class="p_foot">
            <a href="javascript:void(0)" @click="close" class="cancel_btn"
              >取消</a
            >
            <a href="javascript:void(0)" @click="postDownload(Usage.Current)"
              >送出</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageBar from "@/components/PageBar.vue";
import moment from "moment";
import user from "../plugins/user";

var root;

export default {
  components: { PageBar },
  data() {
    return {
      Breadcrumbs: [],
      Categories: [],
      CategorySummery: {},
      Summery: {},
      Years: [],
      Page: parseInt(this.$route.query.p || 1),
      MaxPage: 0,
      Pages: [],
      SortBy: this.$route.query.o || 0,
      Keyword: this.$route.query.key || "",
      Keyword2: this.$route.query.key2 || "",
      Author: this.$route.query.a || "",
      FileTitle: this.$route.query.ft || "",
      StartDay: this.$route.query.s || "",
      EndDay: this.$route.query.e || "",
      SelectedCategories: [],
      Count: 0,
      PageSize: parseInt(this.$route.query.ps || 10),
      StartIndex: 0,
      EndIndex: 0,
      List: [],
      SelectedYears: [],
      Type: this.$route.query.type || 0,
      Usage: {
        Current: null,
        Uses: [],
        Else: "",
        ShowSubmit: false,
      },
    };
  },
  async mounted() {
    this.setBase("media_platform");
    var [summery, categories] = await Promise.all([
      this.$api.getJournalSummery(),
      this.$api.getJournalCategories(),
    ]);
    this.Summery = summery;
    root = { Children: [], JournalCategoryID: null };
    this.createMenu(categories, root);
    this.Categories = root.Children;

    this.load();
  },
  methods: {
    load() {
      this.doload(true);
    },
    async doload(update) {
      var list = await this.$api.getJournals({
        type: this.Type,
        key: this.Keyword,
        name: this.Keyword2,
        a: this.Author,
        jt: this.FileTitle,
        start: this.StartDay,
        end: this.EndDay,
        order: this.SortBy,
        p: this.Page,
        ps: this.PageSize,
        filter: this.SelectedCategories.join(","),
        ys: this.SelectedYears.join(","),
      });
      if (update) {
        var ys = [];
        for (var y in list.Years) {
          ys.push({
            Count: list.Years[y],
            Year: y,
          });
        }
        ys.reverse();
        this.Years = ys;
        this.CategorySummery = list.Categories;
      }
      this.Pages = [];
      this.MaxPage = Math.ceil(list.Count / this.PageSize);
      if (this.MaxPage < this.Page && this.MaxPage >= 1) {
        this.Page = 1;
        this.doload(update);
        return;
      }
      var basePage = Math.floor(this.Page / 10) * 10;
      for (var p = 1; p <= 10; p++) {
        if (this.Page % 10 == 0) {
          basePage = Math.floor((this.Page - 1) / 10) * 10;
        }
        var page = basePage + p;
        if (page > this.MaxPage) {
          break;
        }
        this.Pages.push(page);
      }
      this.Count = list.Count;
      this.StartIndex = (this.Page - 1) * this.PageSize;
      this.EndIndex = this.StartIndex + this.PageSize;
      if (this.EndIndex > this.Count) {
        this.EndIndex = this.Count;
      }
      list.Data.forEach((j) => {
        j.Authors = list.Authors[j.JournalID];
        j.Keywords = [];
        if (j.Keyword) {
          j.Keywords = j.Keyword.split(",");
        }
      });

      this.List = list.Data;
    },
    next10() {
      var p = this.Pages[this.Pages.length - 1] + 1;
      if (p < this.MaxPage) {
        this.showPage(p);
      } else {
        alert("沒有下十頁了");
      }
    },
    prev10() {
      var p = this.Pages[0] - 1;
      if (p > 0) {
        this.showPage(p);
      } else {
        alert("沒有上十頁了");
      }
    },
    createMenu(menu, parent) {
      menu.forEach((m) => {
        if (m.Preserve) {
          return;
        }
        if (!m.Children) {
          m.Children = [];
        }
        if (m.ParentJournalCategoryID == parent.JournalCategoryID) {
          if (!parent.Children) {
            parent.Children = [];
          }
          parent.Children.push(m);
          this.createMenu(menu, m);
          parent.Children.sort((a, b) => a.Sort - b.Sort);
        }
      });
    },
    showPage(p) {
      this.Page = p;
      this.search2();
    },
    switchType(t) {
      if (t == this.Type) {
        return;
      }
      this.SelectedCategories = [];
      this.SelectedYears = [];
      this.$router.push("/journals?type=" + t);
    },
    search() {
      this.$router.push(
        "/journals?type=" +
          this.Type +
          "&key=" +
          encodeURIComponent(this.Keyword)
      );
    },
    search2() {
      var url = "/journals?type=" + this.Type;
      var data = {
        key: this.Keyword,
        key2: this.Keyword2,
        a: this.Author,
        ft: this.FileTitle,
        s: this.StartDay,
        e: this.EndDay,
        p: this.Page,
        ps: this.PageSize,
        o: this.SortBy,
      };
      for (var key in data) {
        if (data[key]) {
          url += `&${key}=${encodeURIComponent(data[key])}`;
        }
      }
      var _this = this;
      this.$router.push(url).catch(err=> {
        _this.doload(true);
      });
    },
    async download(j) {
      if (user.user) {
        await this.$api.downloadJournalLog(j.JournalID, {});
        window.open(window.filters.contentCDN(j.JournalFileUrl));
      } else {
        this.Usage = {
          Uses: [],
          Else: "",
          ShowElse: false,
          Current: j,
        };
        $(".downloadUsage").removeClass("hide");
      }
    },
    async postDownload(j) {
      if (this.Usage.Uses.length == 0 && !this.Usage.Else) {
        return;
      }
      var usage = this.Usage.Uses;
      if (!this.Usage.Else) {
        usage.push(this.Usage.Else);
      }
      await this.$api.downloadJournalLog(j.JournalID, {
        Usage: usage.join(","),
      });
      window.open(window.filters.contentCDN(j.JournalFileUrl));
      $(".downloadUsage").addClass("hide");
    },
    close() {
      $(".downloadUsage").addClass("hide");
    },
    goto(j) {
      window.open(
        window.location.href.replace("journals", "journal/" + j.JournalID)
      );
    },
  },
  watch: {
    $route() {
      this.Type = this.$route.query.type || 0;
      this.Keyword = this.$route.query.key || "";
      this.Keyword2 = this.$route.query.key2 || "";
      this.Author = this.$route.query.a || "";
      this.FileTitle = this.$route.query.ft || "";
      this.StartDay = this.$route.query.s || "";
      this.EndDay = this.$route.query.e || "";
      this.PageSize = parseInt(this.$route.query.ps || 10);
      this.Page = parseInt(this.$route.query.p || 1);
      this.SortBy = this.$route.query.o || 0;
      this.load();
    },
    PageSize() {
      this.search2();
    },
    SortBy() {
      this.search2();
    },
    SelectedCategories() {
      this.page = 1;
      this.doload(false);
    },
    SelectedYears() {
      this.page = 1;
      this.doload(false);
    },
  },
  computed: {
    ShowNext10() {
      return parseInt(this.Pages[this.Pages.length - 1]) + 1 < this.MaxPage;
    },
    ShowPrev10() {
      return parseInt(this.Pages[0]) > 10;
    },
  },
};
</script>